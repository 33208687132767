import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Acerca | PristineOffice Cleaning
			</title>
			<meta name={"description"} content={"Superando Sus Expectativas Cada Día"} />
			<meta property={"og:title"} content={"Acerca | PristineOffice Cleaning"} />
			<meta property={"og:description"} content={"Superando Sus Expectativas Cada Día"} />
			<meta property={"og:image"} content={"https://serenitrefuge.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://serenitrefuge.com/img/190401.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://serenitrefuge.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://serenitrefuge.com/img/190401.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://serenitrefuge.com/img/190401.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://serenitrefuge.com/img/190401.png"} />
			<meta name={"msapplication-TileImage"} content={"https://serenitrefuge.com/img/190401.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://serenitrefuge.com/img/2.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Nuestros Servicios de Limpieza
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				En PristineOffice Cleaning Services, vamos más allá para garantizar que su espacio de trabajo esté inmaculado y sea acogedor. Nuestra amplia gama de servicios está diseñada para satisfacer las necesidades únicas de cada oficina, proporcionando un entorno limpio y saludable para su equipo. Descubra los excepcionales servicios que ofrecemos y cómo pueden transformar su oficina en un prístino refugio de productividad.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://serenitrefuge.com/img/3.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Soluciones de limpieza de oficinas a medida
					<br />
					<br />
					Entendemos que cada oficina es diferente, por eso ofrecemos soluciones de limpieza personalizadas que se adaptan a sus necesidades específicas. Nuestro equipo trabaja estrechamente con usted para desarrollar un plan de limpieza que tenga en cuenta sus prioridades y su calendario. Desde el mantenimiento diario hasta las limpiezas a fondo periódicas, PristineOffice cuenta con la flexibilidad y la experiencia necesarias para ofrecer resultados excepcionales.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
					Equipos de limpieza especializados
						<br />
						<br />
						Limpieza general de oficinas: Limpieza diaria o semanal para mantener un espacio de trabajo limpio y organizado, incluyendo quitar el polvo, aspirar y retirar la basura.
						<br />
						Desinfección de baños: Limpieza y desinfección a fondo de los baños para garantizar la higiene y el confort de sus empleados y visitantes.
						<br />
						Limpieza de la sala de descanso: Mantener su sala de descanso limpia y acogedora, con limpieza regular de superficies, electrodomésticos y suelos.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://serenitrefuge.com/img/4.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Image src="https://serenitrefuge.com/img/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Experimente la excelencia de Pristine
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Elija PristineOffice Cleaning Services y experimente la diferencia que puede marcar un servicio de limpieza profesional. Nos dedicamos a crear un entorno limpio, saludable y productivo para su oficina. Deje que nosotros nos encarguemos de la limpieza para que usted pueda centrarse en lo que mejor sabe hacer: dirigir su negocio.
					</Text>
					<Button
						background="#60bae1"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Contáctenos
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});